export default {
    name: 'add-existing-member',
    props: {
        modalShow: {
            default: false
        },
    },
    data() {
        return {
            displayModal: false,
            firstName: '',
            middleName: null,
            lastName: '',
            dob: '',
            playerId: '',
            dobConstraint: new Date(),
            errorMessage: 'Invalid Input',
            isValid: true,
        }
    },
    created() {
        this.displayModal = this.modalShow,
        this.dobConstraint.setYear(new Date().getFullYear() - 20)
    },
    methods: {
        validate() {
            if (!this.firstName) {
                    this.errorMessage = 'Required field'
                    return false
            }
            if (!this.lastName) {
                this.errorMessage = 'Required field'
                return false
            }
            if (!this.dob) {
                this.errorMessage = 'Required field'
                return false
            }
            if (!this.playerId) {
                this.errorMessage = 'Required field'
                return false
            }
            else { 
                return true
            }
        },
        hideModal() {
            this.displayModal = false;
        },
        onSubmit() {
            this.isValid = this.validate()
            if (this.isValid) {
                this.$http.post('/add-existing-gc-members-leads', {
                    first_name: this.firstName,
                    middle_name: this.middleName ? this.middleName : '',
                    last_name: this.lastName,
                    dob: this.dob,
                    player_id: this.playerId
                })
                .then(response => {
                    if (response.status == 200) {
                        this.$helper.showMessage(response.data.message);
                        this.hideModal();
                    }

                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
}
