<template>
    <div class="topbottom_padding">
        <b-modal v-model="modalShow" hide-footer>
            <div class="white_block">
                <div class="information-item">
                    <h3>Before we start:</h3>
                    <p>
                        We require your identity to be verified before we can
                        approve your account and provide complete access to the
                        Grand Casino web app. This process can also be completed
                        on the mobile version. To download go to Apple Store or
                        Google Play Store.
                    </p>
                </div>
                <div class="information-item">
                    <h3>You will need:</h3>
                    <ul class="list-group">
                        <li>A device (laptop/desktop) with webcam access</li>
                        <li>
                            New Zealand Driver License (or) New Zealand Passport
                        </li>
                    </ul>
                </div>
                <div class="information-item">
                    <ul class="list-group">
                        <h3>Total time:</h3>
                        <li>Approx. 5 minutes</li>
                    </ul>
                </div>
                <div class="information-item">
                    <ul class="list-group">
                        <h3>R20:</h3>
                        <li>Entry to Grand Casino is R20</li>
                        <li>Sign-up to Grand Rewards is R20</li>
                    </ul>
                </div>
                <div class="mt-2">
                    <b-button block @click="gotoSignup">OK</b-button>   
                </div>
            </div>
        </b-modal>

        <div class="container">
            <h1>Grand Casino Member Portal</h1>
            <div class="golden_button">
                <h2>
                    <template v-if="preSignupOffer">
                        {{ preSignupOffer.title }}
                        <span
                            >Offer valid until
                            {{ preSignupOffer.active_end_date }}
                        </span>
                    </template>
                    <template v-else>
                        Sign up to Grand Rewards is FREE and gives you 10% off food & beverage in the Grand Bar & Restaurant.
                    </template>
                </h2>
                <a href="#" @click="openModal">Join Now</a>
            </div>

            <!--Benefits Block -->
            <div class="block">
                <h2>Benefits of a Grand Casino membership</h2>
                <div v-html="membership_html" class="white_block white_block1"></div>
            </div>
            <!--End Benefits Block -->

            <!--Last Block -->
            <div class="row">
                <!-- <div class="col-md-6" style="margin-bottom: 2.5rem;">
                    <h2>Existing Members</h2>
                    <div class="white_block">
                        <p>
                            Are you an existing Grand Casino member? It's easy
                            to set up your account, click the button below and
                            follow the steps.
                        </p>
                        <a @click="showExistingMember" class="btn"
                            >Set up account</a
                        >
                    </div>
                </div> -->
                <div class="col-md-12">
                    <h2>New Members</h2>
                    <div class="white_block newm_block">
                        <p>
                            It's easy to become a Grand Casino member, it takes
                            5 minutes to set up your account and you'll also
                            need your drivers licence on hand.
                        </p>
                        <a href="#" class="btn" @click="openModal">Join Now</a>
                    </div>
                </div>
            </div>
            <AddExistingMember :modalShow="isEmModal" />
            <!--End Last Block -->
        </div>
    </div>
</template>

<script>
import AddExistingMember from '@/components/add_existing_member'
import { BModal } from 'bootstrap-vue'
export default {
    components: { AddExistingMember, BModal },
    created() {
        this.$emit(`update:layout`, 'NoAuthLayout')
        this.fetchConfig()
    },
    data() {
        return {
            isEmModal: false,
            membership_html: null,
            preSignupOffer: null,
            modalShow: false,
        }
    },
    methods: {
        showExistingMember() {
            this.isEmModal = true
            this.$bvModal.show('emembermodal')
        },
        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                this.membership_html =
                    resp.data.system_settings.length > 0
                        ? resp.data.system_settings[0]
                              .benefits_of_a_grand_casino_membership
                        : '<div />'
                this.preSignupOffer =
                    resp.data.offers.length > 0 ? resp.data.offers[0] : null
            } catch (e) {
                console.error(e)
            }
        },
        openModal() {
            this.modalShow = true
        },
        gotoSignup() {
            this.$router.push('signup')
        },
    },
}
</script>
<style scoped>
.white_block {
    min-height: 193px;
}
</style>
